import React from 'react'

const Footer = () => {
    return (
        <>
            <footer className="bg-white mt-8">
            <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
                <p className="mt-8 text-center text-base text-gray-400">
                &copy; Entuit Enterprise Solutions Inc., 2023
                </p>
            </div>
            </footer>
        </>
    )
}

export default Footer
